<template>
  <div>
  <div class="attach-wrapper" v-if="!host">
    <b-row class="align-items-center" v-if="!homeworkStudentId">
      <b-col cols="auto" 
             v-if="view === 'block'" 
             :data-url="uploadUrl">
        <qrcode-vue :value="uploadUrl"
                    :size="120"
                    v-if="!showAPIQR"
                    class="font-size-0"
                    background="transparent"
                    level="Q" />
        <img v-if="externalImgSrc && showAPIQR" 
             :src="externalImgSrc" 
             alt=""/>
      </b-col>
      <b-col>
        <label class="text-center" :for="`block-${blockId}-upload`">
          <div>
            <template v-if="view === 'block'">
              {{ $t('scan_qr_code_or') }}
              <span class="text-primary text-lowercase">{{ $t('select') }}</span>
            </template>
            <template v-else>{{ $t('select') }}</template>
            <ImageSvg class="ml-2"/>
          </div>
          <div class="text-muted small">{{ $t('image_requirements') }}</div>
        </label>
      </b-col>
    </b-row>
    <div v-if="homeworkStudentId && !uploadedFiles.length">{{ $t('no_files_uploaded') }}</div>
    <div v-if="files.length || uploadedFiles.length">
      <hr v-if="!homeworkStudentId"/>
      <div>
        <div v-for="(f, find) in fileImgs" :key="`img-${find}`" class="img-preview">
          <img :src="f" alt=""/>
          <a class="close-btn" @click="removeImg(find)"><CloseSvg/></a>
        </div>
        <div v-for="(f, find) in uploadedFiles" 
             :key="`upl-img-${find}`" 
             class="img-preview">
          <img :src="f" alt="" v-img/>
          <a class="close-btn" @click="removeUploadedImg(f)" 
             v-if="!selectedUser && !homeworkStudentId">
            <CloseSvg/>
          </a>
        </div>
      </div>
      <div v-if="files.length">
        <hr/>
        <div class="text-center">
          <button class="btn-themed"
                  @click="uploadImage">
            {{ $t('save') }}
          </button>
        </div>
      </div>
    </div>
    
    <input type="file"
           class="d-none"
           accept="image/png,image/jpg,image/jpeg"
           multiple
           @change="processFile($event)"
           :id="`block-${blockId}-upload`"/>
  </div>
  </div>
</template>

<script>

import ImageSvg from "@/assets/svg/image.svg"
import CloseSvg from "@/assets/svg/close.svg"
import QrcodeVue from 'qrcode.vue'
import {ConstructorService} from "../../../services/api.service"

export default {
  name: "IM02UploadPart",
  components: {
    ImageSvg,
    CloseSvg,
    QrcodeVue
  },
  props: {
    blockId: {},
    slideId: {},
    userId: {},
    lessonId: {},
    materialId: {},
    viewMode: {},
    uploadedFiles: {},
    selectedUser: {},
    homeworkStudentId: { default: null },
    view: { type: String, default: 'block' },
    host: { type: Boolean, default: false }
  },
  computed: {
    uploadUrl() {
      return `https://${location.host}/block/upload/${this.blockId}/${this.slideId}/${this.userId}/${this.viewMode}/${this.id}/${this.hash}`
    },
    id() {
      return ['selfstudy'].includes(this.viewMode) ? this.materialId : this.lessonId;
    },
    hash() {
      let str = `${this.slideId}-${this.blockId}-${this.userId}-${this.id}-${this.viewMode}`
      return this.hashCode(str)
    },
    externalImgSrc() {
      return `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(this.uploadUrl)}&size=120x120`
    }
  },
  data() {
    return {
      sizelimit: 5, // in MB 
      files: [],
      fileImgs: [],
      showAPIQR: true
    }
  },
  methods: {
    processFile(event) {
      let tmpFiles = event.target.files
      for(const file of tmpFiles) {
        if(file.size > (this.sizelimit * 1024 * 1024)) {
          this.$error(this.$t('file_size_is_too_big'))
          return
        } else {
          let index = this.files.length
          this.files.push(file)
          let reader = new FileReader()
          reader.onload = (e) => {
            this.$set(this.fileImgs, index, e.target.result)
          }
          reader.readAsDataURL(file)
        }
      }
    },
    async uploadImage() {
      let formData = new FormData()
      this.files.forEach(file => {
        formData.append('image[]', file)
      })
      let res = await ConstructorService.saveImages(this.slideId, this.blockId, this.userId, this.id, this.viewMode, formData);
      this.files = []
      this.fileImgs = []
      this.$emit('filesUploaded', res.data.data);
    },
    removeImg(index) {
      this.files.splice(index, 1)
      this.fileImgs.splice(index, 1)
    },
    async removeUploadedImg(url) {
      let res = await ConstructorService.deleteImage(this.slideId, this.blockId, this.userId, this.id, this.viewMode, url);
      this.$emit('filesUploaded', res.data.data);
    },
    hashCode(str) {
      const crypto = require('crypto');
      return crypto.createHash('sha1').update(str).digest('hex');
    }
  }
}
</script>

<style scoped lang="scss">
.font-size-0 {
  font-size: 0;
}
.attach-wrapper {
  background: #F2FBFF;
  border: 1px dashed $text-themed;
  box-sizing: border-box;
  border-radius: 11px;
  padding: 36px;
  label {
    margin-bottom: 0;
    display: block;
  }
  hr {
    background: #CFEDFB;
    margin-top: 22px;
    margin-bottom: 22px;
  }
  .img-preview {
    position: relative;
    display: inline-block;
    margin-right: 18px;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
    }
    .close-btn {
      cursor: pointer;
      box-shadow: 0px 0px 10px rgba(20, 0, 51, 0.2);
      border-radius: 100%;
      font-size: 0;
      svg {
        width: 24px;
        height: 24px;

      }
      position: absolute;
      right: -10px;
      top: -10px;
    }
  }
  .btn-themed {
    width: 100%;
    max-width: 250px;
  }
}
</style>
